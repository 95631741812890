import axios from 'axios'
import Qs from 'qs'
// create an axios instance
axios.defaults.withCredentials = true
axios.defaults.baseURL = 'http://47.97.84.75:8080'

const service = axios.create({
    timeout: 5000 // request timeout
  })
  
  // 设置请求拦截器
service.interceptors.request.use(config => {
    // 参数转换为表单模式
   if (config.data) {
      config.data = Qs.stringify(config.data)
    }
    // 设置请求头
    config.headers = {
      'Content-Type': 'application/x-www-form-urlencoded', // 模拟form表单方式提交请求
      'Access-Control-Allow-Origin': '*',
      // 'Access-Token': '' // 设置token
    }
    return config
  }, error => {
    console.log(error) // for debug
    Promise.reject(error)
  })
  
  // 设置响应拦截器
  service.interceptors.response.use(
    response => {

      response = response.data
      
      return response
    },
    error => {
      console.log('err' + error) // for debug
    //   Message({
    //     message: error.message,
    //     type: 'error',
    //     duration: 5 * 1000
    //   })
    return Promise.reject(error)
})
  export default service